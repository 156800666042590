import React from 'react'

import './styles.css'

export const LogoSection = (props) => {
  return (
    <div className="pt-5 pb-1  xs-pb-1 logo-section">
      <div className="col-12 mb-3 xs-mb-1  image-text-section-center text-center"><h3>{props.header}</h3></div>
      <div className="layout-body logo-section-container">
        <div className="grid pt-1 pb-1 logo-container">
          {props.techLogos.map(logo => <div className="logo-section-logo-wrapper mb-4" key={`${logo} logo`}>
            <img src={logo} className="logo-section-logo"/>
          </div>)}
        </div>     
      </div>
    </div>
  )
}


