import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
import { PageHeader } from '../components/PageHeader'
import { ServicesDescriptions } from '../components/ServicesDescriptions'

import { CTASection } from '../components/CTASection'
import { LogoSection } from '../components/LogoSection'

import ServicesArray from "../utils/servicesArray.js"
import techLogos from "../utils/techLogos.js"


const Index = () => {
  return (
    <NovaLayout title="Services | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <PageHeader prompt="How We Can Help" heading="Our Marketing Services" />
      <ServicesDescriptions servicesArray={ServicesArray} />
      <LogoSection header="Our Favourite Tech" techLogos={techLogos} />
      <CTASection 
        heading="How can we help?"
        prompt="Let's Get Started"
        buttonText="Get Started"
        gtaText="gtm-services-cta-section-get-started"
      />
    </NovaLayout>
  )
}

export default Index
