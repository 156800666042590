import finmo from '../images/NovaTechLogos/finmo.png'
import google from '../images/NovaTechLogos/google.png'
import referralrock from '../images/NovaTechLogos/referralrock.png'
import hubspot from '../images/NovaTechLogos/hubspot.png'
import gatsby from '../images/NovaTechLogos/gatsby.png'
import contentful from '../images/NovaTechLogos/contentful.png'

const techLogos= [
    finmo,
    hubspot,
    google,
    gatsby,
    contentful,
    referralrock
]

export default techLogos

