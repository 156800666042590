import React from 'react'

import './styles.css'



export const ServicesDescriptions = (props) => {
  return (
    <div className="pt-3 xs-pt-1 pb-3 xs-pb-1 block-purple section-shadow">
      <div className="layout-body">
        <div className="grid mt-5 pt-1 pb-1 xs-mt-3">
          {props.servicesArray.map(service => <div className="service-descriptions-service mb-4" key={service.serviceName}>
            <h5>{service.serviceName}</h5>
            <p className="text-medium mt-1">
              {service.description}
            </p>
          </div>)}
        </div>     
      </div>
    </div>
  )
}


