import React from 'react'
import './styles.css'



export const PageHeader = (props) => {
  return (
    <div className="page-header pt-8 xs-pt-3 pb-8 xs-pb-3">
      <div className="layout-body">
        <div className="grid">
          <div className="col">
            <div className='hero-teaser-text text-center'>
              {props.prompt}
            </div>
            <div>
              <h2 className="text-center">{props.heading}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


